'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var scrollSpeed = 1000;

  //
  // Helper functions
  //

  function doScroll(top) {
    if ('scrollTo' in window) {
      if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: top
        });
      } else {
        window.scrollTo(0, top);
      }
    } else {
      $('html, body').animate({
        scrollTop: top
      }, scrollSpeed);
    }
  }

  gcls.scrollTo = function ($element, adjustment) {
    var top = 0;
    if ($element.jquery && $element.length === 1) {
      top = $element.offset().top;
    } else {
      var temp = parseInt($element, 10);
      if (!gcls.isNaN(temp)) {
        top = temp;
      }
    }

    var topAdjustment = adjustment || 0;
    if (gcls.isNaN(adjustment)) {
      topAdjustment = 0;
    }
    top += topAdjustment;
    doScroll(top);
  };

  //
  // Init functions
  //

  function initEvents() {
    var selector = '[data-gcls-action="scroll-to-top"]';
    var $selector = $(selector);
    $selector.on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      gcls.scrollTo(0, 0);
    });
  }

  $(window).on('load', function () {
    initEvents();
  });
}(jQuery));
