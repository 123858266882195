'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.ui === 'undefined') {
    window.gcls.ui = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var selectorInitDataTable = '[data-gcls-action="init-data-table"]';
  var selectorLastUpdated = '[data-gcls-last-updated="1"]';
  var selectorSearchWrapper = '[data-gcls-wrapper="search"]';
  var selectorShowCalendar = '[data-gcls-action="show-calendar"]';

  //
  // Helper functions
  //

  function initDataTable($table) {
    var numColumns = $table.find('thead th').length;
    var selectorSettings = $table.data('gcls-table-settings');
    var settings = [];
    if (selectorSettings) {
      settings = JSON.parse($(selectorSettings).html());
    }
    var columns = [];
    var order = [
      [0, 'asc']
    ];
    var zeroRecords = settings.zero_records || 'No items found.';

    if (settings.columns) {
      columns = settings.columns;
    } else {
      for (var i = 0; i < numColumns; i += 1) {
        columns.push(null);
      }
    }
    if (settings.order) {
      order = settings.order;
    }

    $table.DataTable({
      columns: columns,
      dom: 'f<"table-responsive"t>',
      info: false,
      language: {
        search: 'Filter:',
        zeroRecords: zeroRecords
      },
      order: order,
      paging: false
    });
  }

  //
  // Init functions
  //

  function initDataTables() {
    $(selectorInitDataTable).each(function () {
      initDataTable($(this));
    });
  }
  gcls.ui.initDataTables = initDataTables;

  function initScrollToLastUpdated(adjustment) {
    var $element = $(selectorLastUpdated);
    if ($element.length === 1) {
      gcls.scrollTo($element, (adjustment || 0));
    }
  }
  gcls.ui.initScrollToLastUpdated = initScrollToLastUpdated;

  function initSearchFocus() {
    $(selectorSearchWrapper).on('shown.bs.collapse', function () {
      $(this).find('input:first').focus();
    });
  }
  gcls.ui.initSearchFocus = initSearchFocus;

  function initShowCalendar(form) {
    $(selectorShowCalendar).on('click', function (e) {
      form.processCalendarShow($(this), e);
    });
  }
  gcls.ui.initShowCalendar = initShowCalendar;
}(jQuery));
