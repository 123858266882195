'use strict';

(function ($) {
  // Configure "global" variables
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.form === 'undefined') {
    window.gcls.form = {};
  }
  if (typeof window.gcls.form.forms === 'undefined') {
    window.gcls.form.forms = {};
  }
  if (typeof window.gcls.form.validators === 'undefined') {
    window.gcls.form.validators = {};
  }
  if (typeof window.gcls.modals === 'undefined') {
    window.gcls.modals = {};
  }
  if (typeof window.gcls.timesheet === 'undefined') {
    window.gcls.timesheet = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var modeHide = 'hide';
  var modeShow = 'show';
  var regexTimeShortcut1 = /^((0?[1-9]|1[012])(:[0-5]\d){1,2})$/;
  var regexTimeShortcut2 = /^(0?[1-9]|1[012])\s*([AaPp])[Mm]?$/;
  var regexTimeShortcut3 = /^((0?[1-9]|1[012])(:[0-5]\d){1,2})\s*([AaPp])?$/;
  var selectorForm = '#gcls-form-timesheet-day';
  var selectorModalAudit = '#modal-audit';
  var selectorModalConfirm = '#modal-confirm';
  var selectorModalError = '#modal-error';
  var selectorModalUnapprove = '#modal-timesheet-unapprove';
  var selectorModalUnsubmit = '#modal-timesheet-unsubmit';
  var selectorOffcanvas = '#gcls-offcanvas-timesheet';
  var $offcanvas;

  //
  // Helper functions
  //

  function formatTimeAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var amPm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    if (parseInt(hours, 10) === 0) {
      hours = 12;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    return (hours + ':' + minutes + ' ' + amPm);
  }

  function getTime(val, date) {
    var dateFormatted;
    var valFormatted = val.replace(/^\s+$/g, '').replace(/(\d)([a|p])(m)$/, '$1 $2$3');
    var time = Date.parse(valFormatted);
    if (gcls.isNaN(time)) {
      if (typeof date === 'undefined') {
        var temp = new Date();
        var mm = temp.getMonth() + 1;
        var dd = temp.getDate();
        var yyyy = temp.getFullYear();
        dateFormatted = mm + '/' + dd + '/' + yyyy;
      } else {
        dateFormatted = date;
      }
      time = Date.parse(dateFormatted + ' ' + valFormatted);
    }
    return (gcls.isNaN(time) ? -1 : time);
  }

  function isTimeSequenceValid($timeIn, $timeOut) {
    var isValid = true;
    if ($timeIn.length === 1 && $timeOut.length === 1) {
      var date = $timeIn.data('gcls-date');
      var timeIn = getTime($timeIn.val(), date);
      var timeOut = getTime($timeOut.val(), date);
      isValid = (timeOut > timeIn);
    }
    return isValid;
  }

  function loadAuditModal($modal, url, data) {
    var errorMsg = $modal.data('gcls-error-message');
    var errorTitle = $modal.data('gcls-error-title');
    var method = 'POST';
    var modalError = $modal.data('gcls-modal-error') || selectorModalError;
    var $modalError = $(modalError);

    $.ajax({
      cache: false,
      data: data,
      dataType: 'json',
      method: method,
      url: url
    }).done(function (response) {
      if (response && response.success && response.data) {
        var $auditDetails = $modal.find('[data-gcls-wrapper="audit-details"]');
        var $auditTitle = $modal.find('[data-gcls-wrapper="audit-title"]');
        var responseData = response.data;
        var html = responseData.html;
        var title = responseData.title;
        $auditDetails.html(html);
        $auditTitle.text(title);
        gcls.spinner.hide();
        $modal.modal('show');
      } else {
        gcls.spinner.hide();
        gcls.modals.displayError($modalError, errorTitle, errorMsg);
      }
    }).fail(function () {
      gcls.spinner.hide();
      gcls.modals.displayError($modalError, errorTitle, errorMsg);
    });
  }

  function modalFormShow($modal, processingId) {
    var $form = $modal.find('form');
    $form.find('[name="id"]').val(processingId);
    $modal.modal('show');
  }

  function processTimeShortcuts($input) {
    var val = gcls.trim($input.val() + '');
    if (val.length > 0) {
      // Process times without am/pm
      if (regexTimeShortcut1.test(val)) {
        var parts = val.split(':');
        var hours = parseInt(parts[0], 10);
        var amPm;
        switch (hours) {
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
            amPm = 'am';
            break;
          default:
            amPm = 'pm';
            break;
        }
        val += (' ' + amPm);
        $input.val(val);
      }

      // Process times with am/pm
      if (regexTimeShortcut2.test(val)) {
        val = val.replace(regexTimeShortcut2, '$1:00 $2m');
        $input.val(val.toLowerCase());
      }

      // Process times with a/p
      if (regexTimeShortcut3.test(val)) {
        val = val.replace(regexTimeShortcut3, '$1 $4m');
        $input.val(val.toLowerCase());
      }
    }
  }

  function toPrecision(number, precision) {
    var factor = Math.pow(10, precision);
    var temp = number * factor;
    var rounded = Math.round(temp);
    return (rounded / factor);
  }

  function calculateSubtotalOther(subtotalId) {
    var subtotal = 0;
    var subtotalTarget = subtotalId;
    if (typeof subtotalId === 'undefined') {
      subtotalTarget = 'hours-other';
    }
    var val;
    $('[data-gcls-subtotal-id="' + subtotalTarget + '"]').each(function () {
      val = parseFloat(this.value);
      if (!gcls.isNaN(val)) {
        subtotal += val;
      }
    });
    var subtotalFormatted = subtotal.toFixed(2);
    $('[data-gcls-subtotal-target="' + subtotalTarget + '"]').text(subtotalFormatted);
  }

  function calculateSubtotalWorked() {
    var subtotal = 0;
    var subtotalPrefix = 'hours-worked';
    var subtotalTarget;
    var timeIn;
    var timeInValid;
    var timeOut;
    var timeOutValid;
    var val;
    var valFormatted;
    var $timeIn;
    var $timeOut;
    for (var i = 0; i < 3; i += 1) {
      $timeIn = $('[name="time_in_' + i + '"]');
      $timeOut = $('[name="time_out_' + i + '"]');
      timeInValid = ($timeIn.length === 1 && $timeIn.valid());
      timeOutValid = ($timeOut.length === 1 && $timeOut.valid());
      if (timeInValid && timeOutValid) {
        timeIn = $timeIn.val() + '';
        timeOut = $timeOut.val() + '';
        timeInValid = timeIn.length > 1;
        timeOutValid = timeOut.length > 1;
      }

      val = 0;
      if (timeInValid && timeOutValid) {
        timeIn = getTime(timeIn);
        timeOut = getTime(timeOut);
        val = parseFloat(Math.abs(timeOut - timeIn) / (60 * 60 * 1000));
        if (gcls.isNaN(val)) {
          val = 0;
        }
      }

      valFormatted = val.toFixed(2);
      subtotalTarget = subtotalPrefix + '-' + i;
      $('[data-gcls-subtotal-target="' + subtotalTarget + '"]').text(valFormatted);
      subtotal += val;
    }

    subtotalTarget = subtotalPrefix;
    var subtotalFormatted = subtotal.toFixed(2);
    $('[data-gcls-subtotal-target="' + subtotalTarget + '"]').text(subtotalFormatted);
  }

  function calculateTotal() {
    var total = 0;
    var totalId = 'hours-total';
    var val;
    $('[data-gcls-subtotal-id="' + totalId + '"]').each(function () {
      val = parseFloat($(this).text());
      if (!gcls.isNaN(val)) {
        total += val;
      }
    });
    var totalFormatted = total.toFixed(2);
    $('[data-gcls-subtotal-target="' + totalId + '"]').text(totalFormatted);
  }

  function roundToHundredth(val) {
    return toPrecision(val, 2).toFixed(2);
  }

  function roundToQuarterHour(val) {
    var ms = 1000 * 60 * 15;
    var time = getTime(val);
    var date = new Date(Math.round(time / ms) * ms);
    return formatTimeAmPm(date);
  }

  function loadFormFields($form, data) {
    var field;
    var fields = data.fields;
    var hasValue;
    var isNoteRequired = false;
    var value;
    var $errorLabel;
    var $formField;
    var $formGroup;

    var keys = Object.keys(fields);
    keys.forEach(function (key) {
      $formField = $form.find('[name="' + key + '"]');
      if ($formField.length === 1) {
        field = fields[key];
        value = (field.value + '');
        hasValue = (value.length > 0);
        $formGroup = $formField.closest('.form-group');
        $errorLabel = $formGroup.find('.invalid-feedback');
        $formField.val(value);

        // Process max-initial attribute
        if ($formField[0].hasAttribute('data-gcls-max-initial')) {
          var maxInitial = hasValue ? value : 0;
          $formField.attr('data-gcls-max-initial', maxInitial);
          $formField.data('gcls-max-initial', maxInitial);
        }

        // Process field errors (when form submit fails)
        if (field.error) {
          $formGroup.removeClass('is-valid').addClass('is-invalid');
          $errorLabel.text(field.error).css('display', '');
        } else {
          $formGroup.removeClass('is-invalid');
          $errorLabel.text('').css('display', 'none');
        }

        // Check is note is required
        if (!isNoteRequired && $formField.data('gcls-note-required')) {
          if (parseFloat(value) > 0) {
            isNoteRequired = true;
          }
        }
      }
    });

    // Show/hide fieldsets
    var fieldsetIds = [
      'fieldset-other-fmla',
      'fieldset-other-payout',
      'fieldset-worked-2'
    ];
    fieldsetIds.forEach(function (fieldsetId) {
      var $fieldset = $form.find('[data-gcls-id="' + fieldsetId + '"]');
      var $formFields = $fieldset.find('input');
      var hasHours = false;
      $formFields.each(function () {
        if (!hasHours) {
          $formField = $(this);
          var key = $formField.attr('name');
          field = fields[key];
          value = (field.value + '');
          hasHours = (value.length > 0);
        }
      });
      var $link = $form.find('[data-gcls-target="' + fieldsetId + '"]');
      var mode = hasHours ? 'show' : 'hide';
      $link.data('gcls-mode', mode).trigger('click');
    });

    // Process whether other hours require a note
    var $wrapperNote = $form.find('[data-gcls-wrapper="note"]');
    var $note = $wrapperNote.find('[name="note"]');
    if (isNoteRequired) {
      $note.rules('add', { required: true });
    } else {
      $note.rules('remove', 'required');
      $formGroup = $note.closest('.form-group');
      $errorLabel = $formGroup.find('.invalid-feedback');
      $formGroup.removeClass('is-invalid is-valid');
      $errorLabel.text('').css('display', 'none');
    }
  }

  function loadFormTotals($form, data) {
    var attributes = data.attributes;
    var keySubtotal;
    var subtotal;
    var subtotalFormatted;
    var target;
    var $target;

    // Process regular hours
    var subtotalsWorked = attributes.subtotals_worked;
    for (var i = 0; i < 3; i += 1) {
      target = 'hours-worked-' + i;
      $target = $form.find('[data-gcls-subtotal-target="' + target + '"]');
      if ($target.length === 1) {
        keySubtotal = 'worked_' + i;
        subtotal = subtotalsWorked[keySubtotal];
        if (typeof subtotal === 'undefined') {
          subtotal = 0;
        }
        subtotalFormatted = subtotal.toFixed(2);
        $target.text(subtotalFormatted);
      }
    }

    // Process subtotals and total
    var keys = [
      'worked',
      'other',
      'total'
    ];
    keys.forEach(function (key) {
      target = 'hours-' + key;
      $target = $form.find('[data-gcls-subtotal-target="' + target + '"]');
      if ($target.length === 1) {
        if (key === 'total') {
          keySubtotal = key;
        } else {
          keySubtotal = 'subtotal_' + key;
        }
        subtotal = attributes[keySubtotal];
        if (typeof subtotal === 'undefined') {
          subtotal = 0;
        }
        subtotalFormatted = subtotal.toFixed(2);
        $target.text(subtotalFormatted);
      }
    });
  }

  function loadFormData($form, data) {
    loadFormFields($form, data);
    loadFormTotals($form, data);
  }

  function loadForm($form, dateSource, dateTarget) {
    var $data = $('#form-data-' + dateSource.replace(/\//g, '-'));
    var data = JSON.parse($data.html());
    loadFormData($form, data);
    if (typeof dateTarget !== 'undefined') {
      // If it is drag and drop, use the drop/target date
      $data = $('#form-data-' + dateTarget.replace(/\//g, '-'));
      data = JSON.parse($data.html());
      var date = data.fields.date.value;
      $form.find('[name="date"]').val(date);
    }
  }
  gcls.timesheet.loadForm = loadForm;

  function processForm($form) {
    var data = $form.serializeArray();
    var method = 'POST';
    var url = $form.attr('action');
    $.ajax({
      cache: false,
      data: data,
      dataType: 'json',
      method: method,
      url: url,
      error: function (jqXHR, textStatus, errorThrown) {
        var textStatusLower = textStatus.toLowerCase();
        if (textStatusLower === 'error') {
          var errorThrownLower = errorThrown.toLowerCase();
          if (errorThrownLower === 'forbidden' || errorThrownLower === 'service unavailable') {
            window.top.location.href = '/login';
          }
        }
      },
      success: function (response) {
        if (response && response.success) {
          $offcanvas.offcanvas('hide');
          window.top.location.reload(true);
        } else {
          loadFormData($form, response.form);
          gcls.spinner.hide();
        }
      }
    });
  }

  function setHeader($element) {
    var sizes = ['xs', 'sm'];
    var prev = '';
    var date;
    var $offcanvasHeader = $offcanvas.find('.offcanvas-header');
    var $span;
    sizes.forEach(function (size) {
      date = $element.data('gcls-slideout-header-' + size);
      if (typeof date !== 'undefined') {
        prev = date;
      }
      if (typeof date === 'undefined' && prev) {
        date = prev;
      }
      $span = $offcanvasHeader.find('[data-gcls-mode="' + size + '"');
      $span.text(date);
    });
  }
  gcls.timesheet.setHeader = setHeader;

  function openOffcanvas(date, dateTarget) {
    var target = date;
    if (typeof dateTarget !== 'undefined') {
      target = dateTarget;
    }
    var $element = $('[data-gcls-date="' + target + '"]');
    var $form = $(selectorForm);
    var $popovers = $('[data-bs-toggle="popover"]');
    gcls.session.checkConnection();
    $popovers.popover('hide');
    setHeader($element);
    loadForm($form, date, dateTarget);
    $offcanvas.offcanvas('show');
  }
  gcls.timesheet.openOffcanvas = openOffcanvas;

  function updateNoteRequired() {
    var $fieldsNoteRequired = $('[data-gcls-note-required="1"]');
    var hasFMLA = false;
    var isNoteRequired = false;
    var msgFieldNames = [];
    var name;
    var numNoteRequired = $fieldsNoteRequired.length;
    var val;
    for (var i = 0; i < numNoteRequired; i += 1) {
      val = parseFloat($fieldsNoteRequired[i].value);
      if (val > 0) {
        name = $fieldsNoteRequired[i].name;
        if (name === 'hours_leave') {
          msgFieldNames.push('A.Leave');
        } else if (name === 'hours_other') {
          msgFieldNames.push('Other');
        } else if (!hasFMLA && name.indexOf('hours_fmla_') === 0) {
          hasFMLA = true;
          msgFieldNames.push('FMLA');
        }
        isNoteRequired = true;
      }
    }

    var $wrapperNote = $('[data-gcls-wrapper="note"]');
    var $note = $wrapperNote.find('[name="note"]');
    if (isNoteRequired) {
      var msgRequired = '<strong>'
        + msgFieldNames.sort().join('</strong> and <strong>')
        + '</strong> hours require a brief explanation.';
      $note.rules('add', {
        required: true,
        messages: {
          required: msgRequired
        }
      });
    } else {
      $note.rules('remove', 'required');
    }
  }

  //
  // Event functions
  //

  function processClearForm($form, e) {
    e.preventDefault();
    e.stopPropagation();
    var $formFields = $form.find('input[type="text"],textarea');
    var $formGroups = $formFields.closest('.form-group');
    var $errorLabels = $formGroups.find('.invalid-feedback');
    var $wrapperNote = $('[data-gcls-wrapper="note"]');
    var $note = $wrapperNote.find('[name="note"]');
    var $showHideLinks = $form.find('[data-gcls-action="show-hide-section"]');

    $errorLabels.text('').css('display', 'none');
    $formGroups.removeClass('is-invalid is-valid');
    $formFields.val('');
    $note.rules('remove', 'required');
    $note.valid();
    $showHideLinks.data('gcls-mode', 'hide').trigger('click');

    calculateSubtotalWorked();
    calculateSubtotalOther();
    calculateTotal();
  }

  function processConfirmSubmit($element, e) {
    var checkWeek1 = $element.data('gcls-check-week1');
    var checkPeriod = $element.data('gcls-check-period');
    var msgWarning = '';
    var $subtotalWeek1 = $('[data-gcls-id="week-subtotal-0"]');
    var $subtotalWeek2 = $('[data-gcls-id="week-subtotal-1"]');
    var subtotalWeek1 = parseFloat($subtotalWeek1.text());
    var subtotalWeek2 = parseFloat($subtotalWeek2.text());
    var hasWeek1 = !gcls.isNaN(subtotalWeek1);
    var hasWeek2 = !gcls.isNaN(subtotalWeek2);
    if (checkWeek1) {
      if (hasWeek1 && subtotalWeek1 < 37.5) {
        msgWarning = $element.data('gcls-confirm-msg-week1');
      }
    }
    if (!msgWarning && checkPeriod) {
      if (hasWeek1 && hasWeek2 && (subtotalWeek1 + subtotalWeek2) < 75) {
        msgWarning = $element.data('gcls-confirm-msg-period');
      }
    }

    if (msgWarning) {
      e.preventDefault();
      e.stopPropagation();

      var $modalConfirm = $(selectorModalConfirm);
      var $modalContent = $modalConfirm.find('[data-gcls-wrapper="modal-content"]');
      var $modalTitle = $modalConfirm.find('[data-gcls-wrapper="modal-title"]');
      var description = $element.data('gcls-confirm-description');
      var msg;
      var title = $element.data('gcls-confirm-title');
      if (msgWarning) {
        msg = '<p><strong class="text-danger">' + msgWarning + '</strong></p>'
          + '<p>' + description + '</p>';
      }

      var url;
      if ($element[0].hasAttribute('href')) {
        url = $element.attr('href');
      } else {
        url = $element.data('gcls-url');
      }

      $modalConfirm.data('gcls-url', url);
      $modalContent.html(msg);
      $modalTitle.text(title);
      $modalConfirm.modal('show');
    } else {
      gcls.spinner.show(0);
    }
  }

  function processOpenOffcanvas($element, e) {
    e.preventDefault();
    e.stopPropagation();
    var $date = $element.closest('[data-gcls-date]');
    var date = $date.data('gcls-date');
    openOffcanvas(date);
  }

  function processRoundMinutes($element) {
    processTimeShortcuts($element);
    if (!$element.valid()) {
      return true;
    }

    var val = gcls.trim($element.val() + '');
    if (val.length > 0) {
      val = roundToQuarterHour(val);
      if (val) {
        $element.val(val);
      }
    }

    var subtotalId = $element.data('gcls-subtotal-id');
    if (typeof subtotalId !== 'undefined') {
      calculateSubtotalWorked();
      calculateTotal();
    }
    return true;
  }

  function processRoundNumber($element) {
    if (!$element.valid()) {
      return true;
    }

    var val = gcls.trim($($element).val() + '');
    if (val.length > 0) {
      val = roundToHundredth(val);
      if (!gcls.isNaN(val)) {
        $element.val(val);
      }
    }

    updateNoteRequired();
    var subtotalId = $element.data('gcls-subtotal-id');
    if (typeof subtotalId !== 'undefined') {
      calculateSubtotalOther(subtotalId);
      calculateTotal();
    }
    var $wrapperNote = $('[data-gcls-wrapper="note"]');
    var $note = $wrapperNote.find('[name="note"]');
    return $note.valid();
  }

  function processShowAuditDate($element, e) {
    gcls.spinner.show();
    e.preventDefault();
    e.stopPropagation();
    var date = $element.data('gcls-date');
    var employeeId = $element.data('gcls-employee-id');
    var supervisorId = $element.data('gcls-supervisor-id');

    var $modal = $(selectorModalAudit);
    var data = {
      date: date,
      employee_id: employeeId,
      supervisor_id: supervisorId
    };
    var url = $modal.data('gcls-url-date');
    loadAuditModal($modal, url, data);
  }

  function processShowAuditPayPeriod($element, e) {
    gcls.spinner.show();
    e.preventDefault();
    e.stopPropagation();
    var employeeId = $element.data('gcls-employee-id');
    var periodYear = $element.data('gcls-period-year');
    var periodNumber = $element.data('gcls-period-number');
    var supervisorId = $element.data('gcls-supervisor-id');

    var $modal = $(selectorModalAudit);
    var data = {
      employee_id: employeeId,
      period_number: periodNumber,
      period_year: periodYear,
      supervisor_id: supervisorId
    };
    var url = $modal.data('gcls-url-pay-period');
    loadAuditModal($modal, url, data);
  }

  function processShowHideSection($element, e) {
    e.preventDefault();
    e.stopPropagation();
    var mode = $element.data('gcls-mode');
    var target = $element.data('gcls-target');
    var $target = $('[data-gcls-id="' + target + '"]');
    if (mode === modeShow) {
      $element.data('gcls-mode', modeHide).text('Hide');
      $target.removeClass('d-none');
    } else {
      $element.data('gcls-mode', modeShow).text('Show');
      $target.addClass('d-none');
    }
  }

  function processUnapprove($element, e) {
    gcls.session.checkConnection();
    e.preventDefault();
    e.stopPropagation();

    var $modal = $(selectorModalUnapprove);
    var $wrapper = $element.closest('tr');
    var processingId = $wrapper.data('gcls-detail-id');
    modalFormShow($modal, processingId);
  }

  function processUnsubmit($element, e) {
    gcls.session.checkConnection();
    e.preventDefault();
    e.stopPropagation();

    var $modal = $(selectorModalUnsubmit);
    var $wrapper = $element.closest('tr');
    var processingId = $wrapper.data('gcls-detail-id');
    modalFormShow($modal, processingId);
  }

  //
  // Init functions
  //

  function initEvents(form) {
    var $form = form.$form;
    var selectorClearForm = '[data-gcls-action="clear-form"]';
    $form.find(selectorClearForm).on('click', function (e) {
      processClearForm($form, e);
    });

    var selectorConfirmSubmit = '[data-gcls-action="confirm-submit"]';
    $(selectorConfirmSubmit).on('click', function (e) {
      processConfirmSubmit($(this), e);
    });

    var selectorOpenOffcanvas = '[data-gcls-action="open-offcanvas"]';
    $(selectorOpenOffcanvas).on('click', function (e) {
      processOpenOffcanvas($(this), e);
    });

    var selectorRoundMinutes = '[data-gcls-action="round-minutes"]';
    var $selectorRoundMinutes = $form.find(selectorRoundMinutes);
    $selectorRoundMinutes.on('blur', function () {
      processRoundMinutes($(this));
    });
    $selectorRoundMinutes.on('keydown', function (e) {
      var key = e.keyCode || 0;
      if (key !== 13) {
        return true;
      }
      return processRoundMinutes($(this));
    });

    var selectorRoundNumber = '[data-gcls-action="round-number"]';
    var $selectorRoundNumber = $form.find(selectorRoundNumber);
    $selectorRoundNumber.on('blur', function () {
      return processRoundNumber($(this));
    });
    $selectorRoundNumber.on('keydown', function (e) {
      var key = e.keyCode || 0;
      if (key !== 13) {
        return true;
      }
      var success = processRoundNumber($(this));
      if (!success) {
        e.preventDefault();
        e.stopPropagation();
      }
      return success;
    });

    var selectorShowAuditDate = '[data-gcls-action="show-audit-date"]';
    $(selectorShowAuditDate).on('click', function (e) {
      processShowAuditDate($(this), e);
    });

    var selectorShowAuditPeriod = '[data-gcls-action="show-audit-pay-period"]';
    $(selectorShowAuditPeriod).on('click', function (e) {
      processShowAuditPayPeriod($(this), e);
    });

    var selectorShowHideSection = '[data-gcls-action="show-hide-section"]';
    $form.find(selectorShowHideSection).on('click', function (e) {
      processShowHideSection($(this), e);
    });

    var selectorUnapprove = '[data-gcls-action="unapprove"]';
    $(selectorUnapprove).on('click', function (e) {
      processUnapprove($(this), e);
    });

    var selectorUnsubmit = '[data-gcls-action="unsubmit"]';
    $(selectorUnsubmit).on('click', function (e) {
      processUnsubmit($(this), e);
    });
  }

  function initOffcanvas() {
    $offcanvas = $(selectorOffcanvas);
    $offcanvas.on('shown.bs.offcanvas', function () {
      $(this).find('[name="time_in_0"]').get(0).focus();
    });
  }

  function initPopovers() {
    var settings = {
      container: 'body',
      html: true,
      placement: 'bottom',
      trigger: 'focus hover'
    };
    $('[data-bs-toggle="popover"]').popover(settings);
  }

  function initValidation(form) {
    form.initValidation(form);
    var $form = form.$form;
    var id = form.id;
    gcls.form.validators[id] = $form.validate({
      groups: {
        fieldsetInOut0: 'time_in_0 time_out_0',
        fieldsetInOut1: 'time_in_1 time_out_1',
        fieldsetInOut2: 'time_in_2 time_out_2'
      },
      submitHandler: function () {
        gcls.spinner.show(0);
        processForm($form);
        return false;
      }
    });

    $.validator.addMethod('timeseq', function (value, element) {
      var $fieldset = $(element).closest('.fieldset-worked');
      var $timeIn = $fieldset.find('[data-gcls-time-in]');
      var $timeOut = $fieldset.find('[data-gcls-time-out]');
      var timeIn = $timeIn[0];
      var timeOut = $timeOut[0];
      var isOptional = (this.optional(timeIn) || this.optional(timeOut));
      var isValid = (isOptional || isTimeSequenceValid($timeIn, $timeOut));
      return isValid;
    }, 'In time must be before out.');
  }

  $(window).on('load', function () {
    var form = gcls.form(selectorForm);
    var id = form.id;
    form.initRequired();
    initValidation(form);
    gcls.form.forms[id] = form;
    gcls.ui.initDataTables();
    initEvents(form);
    initOffcanvas();
    initPopovers();
  });
}(jQuery));
