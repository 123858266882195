'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  var gcls = window.gcls;

  //
  // Helper functions
  //

  gcls.getSize = function () {
    var body = document.querySelector('body');
    var content = window.getComputedStyle(body, ':after').getPropertyValue('content');
    var parts = content.split(': ');
    if (content === 'none' || parts.length !== 2) {
      return 'xs';
    }
    return parts[1].replace(/"/g, '');
  };

  gcls.isDev = function () {
    return $('body').hasClass('is-dev');
  };

  gcls.isNaN = function (value) {
    if (Number.isNaN !== undefined) {
      return Number.isNaN(value);
    }
    /* eslint-disable no-restricted-globals */
    return (typeof value === 'number' && isNaN(value));
    /* eslint-enable no-restricted-globals */
  };

  gcls.isSizeXSmall = function () {
    return gcls.getSize() === 'xs';
  };

  gcls.isSizeSmall = function () {
    return gcls.getSize() === 'sm';
  };

  gcls.isSizeMedium = function () {
    return gcls.getSize() === 'md';
  };

  gcls.isSizeLarge = function () {
    return gcls.getSize() === 'lg';
  };

  gcls.isSizeXLarge = function () {
    return gcls.getSize() === 'xl';
  };

  gcls.isSizeXXLarge = function () {
    return gcls.getSize() === 'xxl';
  };

  var regexTrim = /^\s+|\s+$/g;
  gcls.trim = function (text) {
    return text.replace(regexTrim, '');
  };
}(jQuery));
