'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.spinner === 'undefined') {
    window.gcls.spinner = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var classBackdrop = 'in';
  var keyClassSpinner = 'gcls-class';
  var selectorBackdrop = '.page-backdrop';
  var selectorSpinner = '[data-gcls-spinner]';
  var spinner = gcls.spinner;

  //
  // Helper functions
  //

  spinner.hide = function () {
    var $spinner = $(selectorSpinner);
    $(selectorBackdrop).removeClass(classBackdrop);
    $spinner.removeClass($spinner.data(keyClassSpinner));
  };

  spinner.show = function (closeAfter) {
    var timeout = 10; // seconds
    if (typeof closeAfter !== 'undefined') {
      timeout = closeAfter;
    }
    var $spinner = $(selectorSpinner);
    $(selectorBackdrop).addClass(classBackdrop);
    $spinner.addClass($spinner.data(keyClassSpinner));
    if (timeout > 0) {
      setTimeout(function () {
        // If a JavaScript error is encountered, ensure the page spinner eventually stops
        gcls.spinner.hide();
      }, timeout * 1000);
    }
  };

  //
  // Event functions
  //

  function processOpenSpinner($element) {
    var type = $element.attr('type');
    var isSubmit = (type === 'submit');
    var url;
    if (!isSubmit) {
      if ($element[0].hasAttribute('href')) {
        url = $element.attr('href');
      } else {
        url = $element.data('gcls-submit-url');
      }
    }
    if (isSubmit || url) {
      gcls.spinner.show(0);
    }
  }

  //
  // Init functions
  //

  function initEvents() {
    $('[data-gcls-action="open-spinner"]').on('click', function () {
      processOpenSpinner($(this));
    });
  }

  $(window).on('load', function () {
    initEvents();
  });
}(jQuery));
