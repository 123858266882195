'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.form === 'undefined') {
    window.gcls.form = {};
  }
  if (typeof window.gcls.form.forms === 'undefined') {
    window.gcls.form.forms = {};
  }
  if (typeof window.gcls.form.validators === 'undefined') {
    window.gcls.form.validators = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var classInvalid = 'is-invalid';
  var classValid = 'is-valid';
  var formPrototype = {};
  var regexEmail = /^.+@.+\..+$/;
  var selectorFormGroup = '.form-group';

  //
  // Form functions
  //

  formPrototype.canSubmit = function () {
    var $form = this.$form;
    var canSubmit = true;
    if ($form.has('[data-gcls-action="check-double-submit"]')) {
      canSubmit = this.checkDoubleSubmit();
    }
    return canSubmit;
  };

  formPrototype.checkDoubleSubmit = function () {
    var id = this.id;
    if (typeof id === 'undefined') {
      return false;
    }
    var validator = gcls.form.validators[id];
    if (typeof validator !== 'undefined') {
      return true;
    }

    var $form = this.$form;
    if (!validator.form()) {
      return false;
    }

    var clickCount = parseInt($form.data('gcls-click-count'), 10);
    if (gcls.isNaN(clickCount)) {
      clickCount = 0;
    }
    if (clickCount > 0) {
      // Form was already submitted
      return false;
    }

    clickCount += 1;
    $form.data('gcls-click-count', clickCount);
    return true;
  };

  formPrototype.clearFormFields = function ($formFields) {
    var $formField;
    var $formGroups = $formFields.closest(selectorFormGroup);
    var type;
    $formGroups.removeClass(classInvalid + ' ' + classValid);
    $formFields.data('gcls-prev', '');
    $formFields.each(function (idx, element) {
      $formField = $(element);
      type = $formField.attr('type');
      if (type === 'checkbox' || type === 'radio') {
        $formField.prop('checked', false);
      } else {
        $formField.val('');
      }
    });
  };

  formPrototype.init = function (selector) {
    var $form = $(selector);
    var id;
    if ($form.length === 1) {
      id = $form[0].getAttribute('id');
    }
    this.$form = $form;
    this.id = id;
    this.selector = selector;
  };

  formPrototype.initRequired = function () {
    var $form = this.$form;
    if ($form.length > 0) {
      $form.find('[required]')
        .closest(selectorFormGroup)
        .find('.form-label')
        .addClass('form-label-required');
    }
  };

  formPrototype.initValidation = function (form) {
    $.validator.setDefaults({
      errorClass: 'invalid-feedback error',
      errorPlacement: function (error, element) {
        error.appendTo(element.closest(selectorFormGroup));
      },
      highlight: function (element) {
        form.setInvalid(element);
      },
      onclick: function (element) {
        var target = (element.tagName === 'OPTION') ? element.parentNode : element;
        var validator = gcls.form.validators[$(target.form).attr('id')] || '';
        if (validator && validator.element(target)) {
          form.setValid(target);
        } else if (validator) {
          form.setInvalid(target);
        }
      },
      submitHandler: function (element) {
        if (form.canSubmit()) {
          gcls.spinner.show(0);
          element.submit();
        }
      },
      unhighlight: function (element) {
        form.setValid(element);
      }
    });

    /* eslint-disable no-param-reassign */
    $.validator.methods.email = function (value, element) {
      return this.optional(element) || regexEmail.test(value);
    };
    /* eslint-enable no-param-reassign */

    $.validator.addMethod('alphanum', function (value, element) {
      var isValid = (this.optional(element) || form.validateAlphaNum(value));
      return isValid;
    }, 'Only letters and numbers are allowed.');

    $.validator.addMethod('phone', function (value, element) {
      var isValid = (this.optional(element) || form.validatePhone(value));
      return isValid;
    }, 'Please enter a valid phone number.');

    $.validator.addMethod('year', function (value, element) {
      var isValid = (this.optional(element) || form.validateYear(value));
      return isValid;
    }, 'Please enter a valid year.');
  };

  formPrototype.processCalendarShow = function ($element, e) {
    $element.closest('.input-group').find('[data-gcls-datepicker]').datepicker('show');
    e.preventDefault();
  };

  formPrototype.removeInvalid = function (element) {
    var $element = $(element);
    var $formGroup = $element.closest(selectorFormGroup);
    $formGroup.removeClass(classInvalid);
  };

  formPrototype.removeValid = function (element) {
    var $element = $(element);
    var $formGroup = $element.closest(selectorFormGroup);
    $formGroup.removeClass(classValid);
  };

  formPrototype.setInvalid = function (element) {
    var $element = $(element);
    var $formGroup = $element.closest(selectorFormGroup);
    $formGroup.removeClass(classValid).addClass(classInvalid);
  };

  formPrototype.setValid = function (element) {
    var $element = $(element);
    var $formGroup = $element.closest(selectorFormGroup);
    $formGroup.removeClass(classInvalid).addClass(classValid);
  };

  formPrototype.stashFormFields = function ($formFields) {
    var $formField;
    var prev;
    var type;
    $formFields.each(function (idx, element) {
      $formField = $(element);
      prev = $formField.val();
      type = $formField.attr('type');
      if (type === 'checkbox' || type === 'radio') {
        prev = $formField.prop('checked') ? 'checked' : '';
      }
      $formField.data('gcls-prev', prev);
      if (type === 'checkbox' || type === 'radio') {
        $formField.prop('checked', false);
      } else {
        $formField.val('');
      }
    });
  };

  formPrototype.unstashFormFields = function ($formFields) {
    var $formField;
    var prev;
    var type;
    $formFields.each(function (idx, element) {
      $formField = $(element);
      prev = $formField.data('gcls-prev');
      type = $formField.attr('type');
      if (prev) {
        if (type !== 'checkbox' && type !== 'radio') {
          $formField.val(prev + '');
        } else if (prev === 'checked') {
          $formField.prop('checked', true);
        }
      }
    });
  };

  formPrototype.validateAlphaNum = function (value) {
    var isValid = /^[a-z0-9]+$/i.test(value);
    return isValid;
  };

  formPrototype.validatePhone = function (value) {
    var formatted = value.replace(/\s+/g, '');
    var length = formatted.length;
    var isValidPhone10 = (length === 10 && formatted.match(/^\d{10}$/));
    var isValidPhone12 = (length === 12 && formatted.match(/^\d{3}-\d{3}-\d{4}$/));
    var isValid = (isValidPhone10 || isValidPhone12);
    return isValid;
  };

  formPrototype.validateYear = function (value) {
    var isValid = /^\d{4}$/.test(value);
    return isValid;
  };

  gcls.form = function (selector) {
    var f = Object.create(formPrototype);
    f.init(selector);
    return f;
  };

  //
  // Init functions
  //

  function initFormAutoFocus() {
    var $form = $('[data-gcls-autofocus-form]:first');
    var $field = $form.find(':input[type!=hidden]:first');
    $field.focus();
  }

  $(window).on('load', function () {
    initFormAutoFocus();
  });
}(jQuery));
