'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.modals === 'undefined') {
    window.gcls.modals = {};
  }
  var gcls = window.gcls;

  //
  // Set variables
  //

  var selectorModalConfirm = '#modal-confirm';
  var selectorModalDetails = '#modal-details';
  var selectorModalError = '#modal-error';

  //
  // Event functions
  //

  function displayError($modal, titleCustom, msgCustom) {
    var msg = msgCustom || 'An error occurred.';
    var title = titleCustom || 'Error';
    $modal.find('[data-gcls-wrapper="modal-title"]').text(title);
    $modal.find('[data-gcls-wrapper="modal-content"]').html(msg);
    $modal.modal('show');
  }
  gcls.modals.displayError = displayError;

  function processConfirm($element, e) {
    gcls.session.checkConnection();
    e.preventDefault();
    e.stopPropagation();

    var $modalConfirm = $(selectorModalConfirm);
    var confirmDescription = $element.data('gcls-confirm-description');
    var confirmMsg = $element.data('gcls-confirm-msg');
    var msg = '<p><strong>' + confirmMsg + '</strong></p>';
    var url;
    if ($element[0].hasAttribute('href')) {
      url = $element.attr('href');
    } else {
      url = $element.data('gcls-url');
    }
    if (confirmDescription) {
      msg = '<p>' + confirmMsg + '</p>'
        + '<p><strong>' + confirmDescription + '</strong></p>';
    }
    $modalConfirm.data('gcls-url', url);
    $modalConfirm.find('[data-gcls-wrapper="modal-content"]').html(msg);
    $modalConfirm.modal('show');
  }
  gcls.modals.processConfirm = processConfirm;

  function processConfirmYes(e) {
    e.preventDefault();
    e.stopPropagation();
    var $modalConfirm = $(selectorModalConfirm);
    var location = window.top.location;
    var url = $modalConfirm.data('gcls-url');
    $modalConfirm.modal('hide');
    if (url) {
      gcls.spinner.show();
      url = location.protocol + '//' + location.hostname + url;
      window.top.location.href = url;
    }
  }
  gcls.modals.processConfirmYes = processConfirmYes;

  function processDisplayDetails($element, e) {
    gcls.session.checkConnection();
    e.preventDefault();
    e.stopPropagation();

    var $wrapper = $element.closest('[data-gcls-wrapper="item"]');
    var $wrapperAll = $wrapper.closest('[data-gcls-wrapper="all-items"]');
    var hash = $wrapper.data('gcls-detail-hash');
    var hideEditLink = $wrapper.data('gcls-hide-edit-link');
    var id = $wrapper.data('gcls-detail-id');
    var modalDetails = $wrapperAll.data('gcls-modal-details') || selectorModalDetails;
    var modalError = $wrapperAll.data('gcls-modal-error') || selectorModalError;
    var $modalDetails = $(modalDetails);
    var $modalError = $(modalError);
    var errorMsg = $modalError.data('gcls-error-message');
    var errorTitle = $modalError.data('gcls-error-title');
    var keys = $modalDetails.data('gcls-keys').split(',');
    var keysHtml = $modalDetails.data('gcls-keys-html').split(',');
    var keysList = $modalDetails.data('gcls-keys-list').split(',');
    var post = {};
    var prefix = $wrapperAll.data('gcls-prefix');
    var url = $wrapperAll.data('gcls-url-details');
    var useHash = $modalDetails.data('gcls-use-hash');

    if (useHash) {
      post.hash = hash;
    } else {
      post.id = id;
    }
    $.ajax({
      cache: false,
      data: post,
      dataType: 'json',
      method: 'POST',
      url: url
    }).done(function (response) {
      var $noDetails = $modalDetails.find('[data-gcls-wrapper="no-details"]');
      if (response && response.success && response.data) {
        var data = response.data;
        var isHtml;
        var isList;
        var key;
        var keyPrefixed;
        var numValues = 0;
        var value;
        var $itemContent;
        var $itemWrapper;

        var $editLink = $modalDetails.find('[data-gcls-id="edit-link"]');
        if (hideEditLink) {
          $editLink.removeClass('d-inline').addClass('d-none');
        } else {
          var urlEditBase = $modalDetails.data('gcls-url-base');
          var urlEdit = urlEditBase + (useHash ? hash : id);
          $editLink.attr('href', urlEdit);
          $editLink.removeClass('d-none').addClass('d-inline');
        }

        var name = data.name_for_modal_title;
        $modalDetails.find('[data-gcls-wrapper="modal-title"]').text(name);
        for (var i = 0; i < keys.length; i += 1) {
          key = keys[i];
          keyPrefixed = prefix + '-' + key;
          isHtml = (keysHtml.indexOf(key) !== -1);
          isList = (keysList.indexOf(key) !== -1);
          $itemContent = $modalDetails.find('[data-gcls-id="' + keyPrefixed + '"]');
          $itemWrapper = $modalDetails.find('[data-gcls-wrapper="' + keyPrefixed + '"]');
          value = data[key] || '';
          if (value) {
            if (isList) {
              value = value.join(' &bull; ');
              isHtml = true;
            }
            if (isHtml) {
              $itemContent.html(value);
            } else {
              $itemContent.text(value);
            }
            $itemWrapper.removeClass('d-none');
            numValues += 1;
          } else {
            value = '';
            $itemContent.text(value);
            $itemWrapper.addClass('d-none');
          }
        }

        if (numValues === 0) {
          $noDetails.removeClass('d-none');
        } else {
          $noDetails.addClass('d-none');
        }
        $modalDetails.modal('show');
      } else {
        var error = response.error;
        if (error) {
          errorMsg = error.message;
          errorTitle = error.title;
        }
        displayError($modalError, errorTitle, errorMsg);
      }
    }).fail(function () {
      displayError($modalError, errorTitle, errorMsg);
    });
  }
  gcls.modals.processDisplayDetails = processDisplayDetails;

  //
  // Init functions
  //

  function initEvents() {
    $('[data-gcls-action="confirm"]').on('click', function (e) {
      processConfirm($(this), e);
    });

    $('[data-gcls-action="display-details"]').on('click', function (e) {
      processDisplayDetails($(this), e);
    });
  }

  $(window).on('load', function () {
    initEvents();
    var $modalConfirm = $(selectorModalConfirm);
    $modalConfirm.on('click', '[data-gcls-action="confirm-yes"]', function (e) {
      processConfirmYes(e);
    });
  });
}(jQuery));
