'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.session === 'undefined') {
    window.gcls.session = {};
  }
  if (typeof window.gcls.session.counter === 'undefined') {
    window.gcls.session.counter = {};
  }
  var gcls = window.gcls;

  var decrement = 0; // in seconds
  var regexSearch = /\/search$/;
  var seconds;
  var selectorExpired = '#modal-session-expired';
  var selectorWarning = '#modal-session-warning';
  var timerId;
  var $modalExpired;
  var $modalWarning;

  //
  // Helper functions
  //

  function getSessionLastUpdated() {
    var date;
    var temp = $modalWarning.data('gcls-updated-at');
    var updatedAt = parseInt(temp, 10);
    if (gcls.isNaN(updatedAt)) {
      date = new Date();
    } else {
      date = new Date(updatedAt * 1000);
    }
    return date;
  }

  function getSessionTimeout() {
    var temp = $modalWarning.data('gcls-expires-seconds');
    var sessionSeconds = parseInt(temp, 10);
    if (gcls.isNaN(sessionSeconds)) {
      sessionSeconds = 20 * 60;
    }
    return sessionSeconds;
  }

  function getSessionTimeoutBuffer() {
    var temp = $modalWarning.data('gcls-expires-buffer');
    var bufferSeconds = parseInt(temp, 10);
    if (gcls.isNaN(bufferSeconds)) {
      bufferSeconds = 15;
    }
    return bufferSeconds;
  }

  function getSecondsRemaining() {
    var lastUpdated = getSessionLastUpdated();
    var now = new Date();
    var sessionBuffer = getSessionTimeoutBuffer();
    var sessionSeconds = getSessionTimeout();
    var msElapsed = now.getTime() - lastUpdated.getTime();
    var secondsElapsed = Math.floor(msElapsed / 1000) + sessionBuffer;
    var secondsRemaining = sessionSeconds - secondsElapsed;
    if (secondsRemaining < 0) {
      secondsRemaining = 0;
    }
    if (gcls.isDev()) {
      console.log('elapsed: ' + secondsElapsed);
      console.log('remaining: ' + secondsRemaining);
    }
    return secondsRemaining;
  }

  function getDecrement() {
    var newDecrement = 1;
    var secondsRemaining = getSecondsRemaining();
    if (secondsRemaining >= 600) { // 10 minutes
      newDecrement = 60; // seconds
    } else if (secondsRemaining >= 120) { // 2 minutes
      newDecrement = 30; // seconds
    }
    seconds = secondsRemaining;
    return newDecrement;
  }

  function getUrlLoginProcessed() {
    var base = $modalWarning.data('gcls-url-login');
    var path = window.location.pathname;
    if (path !== '' && regexSearch.test(path)) {
      path = path.replace(regexSearch, '');
    }
    return base + '?url=' + encodeURIComponent(path + window.location.search);
  }

  function initCounter() {
    var path = window.location.pathname;
    var urlLogin = $modalWarning.data('gcls-url-login');
    var urlLogout = $modalWarning.data('gcls-url-logout');
    if (path === urlLogin || path === urlLogout) {
      return;
    }
    var urlLoginProcessed = getUrlLoginProcessed();
    $modalExpired.data('gcls-url-login-processed', urlLoginProcessed);
    $modalWarning.data('gcls-url-login-processed', urlLoginProcessed);
    gcls.session.counter.start();
  }

  function decrementCounter() {
    var decrementNew = getDecrement();
    var wasClosed = $modalWarning.data('gcls-was-closed');
    if (decrementNew !== decrement) {
      decrement = decrementNew;
      clearInterval(timerId);
      timerId = 0;
      timerId = setInterval(decrementCounter, decrement * 1000);
    }

    var $countdownSeconds = $modalWarning.find('[data-gcls-id="countdown-seconds"]');
    if (seconds <= 60 && !wasClosed) {
      $modalWarning.modal('show');
      $countdownSeconds.text(seconds);
    }
    if (seconds <= 0) {
      gcls.session.counter.stop();
      setTimeout(function () {
        $('.modal.show').not(selectorExpired).modal('hide');
      }, 500);
      setTimeout(function () {
        $modalExpired.modal('show');
      }, 750);
    }
  }

  function extendSession() {
    var method = 'GET';
    var urlLogin = $modalWarning.data('gcls-url-login-processed');
    var urlPing = $modalWarning.data('gcls-url-ping');
    gcls.session.counter.stop();
    $modalWarning.modal('hide');
    gcls.spinner.show();
    if (urlPing) {
      $.ajax({
        cache: false,
        method: method,
        url: urlPing,
        complete: function (jqXHR) {
          var json = jqXHR.responseJSON || {};
          var jsonData = json.data || '';
          if (jsonData === 'pong') {
            gcls.session.counter.reset(json.session.updated_at);
            $('.modal-backdrop').remove();
          } else {
            window.top.location.href = urlLogin;
          }
        }
      });
    } else {
      window.top.location.reload();
    }
  }

  function processLogin() {
    var urlLogin = $modalExpired.data('gcls-url-login-processed');
    gcls.spinner.show();
    window.top.location.href = urlLogin;
    return false;
  }

  //
  // Session functions
  //

  gcls.session.checkConnection = function () {
    var method = 'GET';
    var urlLogin = $modalWarning.data('gcls-url-login-processed');
    var urlPing = $modalWarning.data('gcls-url-ping');
    if (urlPing) {
      $.ajax({
        cache: false,
        method: method,
        url: urlPing,
        complete: function (jqXHR) {
          var json = jqXHR.responseJSON || {};
          var jsonData = json.data || '';
          if (jsonData === 'pong') {
            gcls.session.counter.reset(json.session.updated_at);
          } else {
            window.top.location.href = urlLogin;
          }
        }
      });
    }
  };

  gcls.session.counter.reset = function (updatedAt) {
    if (typeof updatedAt !== 'undefined' && !gcls.isNaN(parseInt(updatedAt, 10))) {
      $modalWarning.data('gcls-updated-at', updatedAt);
    }
    $modalWarning.data('gcls-was-closed', 0);
    gcls.spinner.hide();
    initCounter();
  };

  gcls.session.counter.start = function () {
    $modalWarning.data('gcls-was-closed', 0);
    clearInterval(timerId);
    decrement = getDecrement();
    timerId = 0;
    decrementCounter();
    timerId = setInterval(decrementCounter, decrement * 1000);
  };

  gcls.session.counter.stop = function () {
    $modalWarning.data('gcls-was-closed', 0);
    clearInterval(timerId);
  };

  //
  // Init functions
  //

  function initEvents() {
    $modalExpired.on('hide.bs.modal', function () {
      processLogin();
    });

    $modalWarning.on('hide.bs.modal', function () {
      $(this).data('gcls-was-closed', 1);
    });

    $modalWarning.find('[data-gcls-action="extend-session"]').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      extendSession();
    });

    $modalExpired.find('[data-gcls-action="process-login"]').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      processLogin();
    });
  }

  $(window).on('load', function () {
    $modalExpired = $(selectorExpired);
    $modalWarning = $(selectorWarning);
    if ($modalWarning.length === 1) {
      initEvents();
      initCounter();
    }
  });
}(jQuery));
