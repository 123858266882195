'use strict';

(function ($) {
  if (typeof window.gcls === 'undefined') {
    window.gcls = {};
  }
  if (typeof window.gcls.timesheet === 'undefined') {
    window.gcls.timesheet = {};
  }
  var gcls = window.gcls;

  $(window).on('load', function () {
    var draggable = $('[data-gcls-drag="enabled"]').toArray();
    dragula(draggable, {
      copy: true,
      moves: function (el, source, handle) {
        return ($(handle).data('gcls-drag') === 'handle');
      }
    }).on('drop', function (el, target, source) {
      this.cancel(true);
      if (target) {
        var dateDrag = $(source).data('gcls-date');
        var dateDrop = $(target).data('gcls-date');
        if (dateDrag !== dateDrop) {
          gcls.timesheet.openOffcanvas(dateDrag, dateDrop);
        }
      }
    });
  });
}(jQuery));
